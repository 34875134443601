import * as antColors from '@ant-design/colors';

import { FilterContentModel } from '../FilterStore/filterStore';

export type InfoBlockItem = {
  description: string;
  fields: InfoBlockItemFields[];
  id: number;
  isService: boolean;
  name: string;
};

export interface InfoBlockItemFields {
  abbreviation: string;
  description: string;
  filterType: string;
  id: number;
  index: number;
  infoBlockDescription: string;
  infoBlockId: number;
  name: string;
  width: number;
}

export interface ClientFieldRequest {
  customReportId: number;
  fieldId: number;
  customName: string;
}

export interface SetNoteRequest {
  noteId: string;
  numbers: string[] | number[];
  groupNumber: number;
  isAdd: boolean;
}

export interface SetCatalogRequest {
  catalogId: string;
  numbers: string[] | number[];
  direction: boolean;
}

export interface wagonInfoResponse {
  number: number;
  catalogs: CatalogSimpleModel[];
  notes1: NoteSimpleModel[];
  notes2: NoteSimpleModel[];
  notes3: NoteSimpleModel[];
  notes4: NoteSimpleModel[];
}

export interface containerInfoResponse {
  number: string;
  catalogs: CatalogSimpleModel[];
  notes1: NoteSimpleModel[];
  notes2: NoteSimpleModel[];
  notes3: NoteSimpleModel[];
  notes4: NoteSimpleModel[];
}

export interface CatalogSimpleModel {
  id: string;
  title: string;
  description: string;
}

export interface NoteSimpleModel {
  id: string;
  title: string;
  description: string;
}

export interface MoveToCatalogContainerRequest {
  catalogId: string;
  numbers: string[];
  directionbool: boolean;
}

export interface CompanyFieldWidthRequest {
  customReportId: number;
  fieldId: number;
  width: number;
}

export interface WagonItineraryModel {
  started: Date;
  ended: Date | null;
  iskpDate: Date | null;
  pvguDate: Date | null;
  uvguDate: Date | null;
  vigDate: Date | null;
  sendDate: Date | null;
  receiveDate: Date | null;
  commonTariff: number;
  rentTariff: number;
  itineraryId: string;
  wagonNumber: number;
  loadStationCode: string;
  destStationCode: string;
  freightNetto: number;
  loadStationName: string;
  destStationName: string;
  sendNumber: string;
  etsngCode: string;
  freightName: string;
  trainIndex: string;
  senderOkpo: string;
  senderName: string;
  receiverOkpo: string;
  receiverName: string;
}

export interface ContainerItineraryModel {
  started: Date;
  ended: Date | null;
  iskpDate: Date | null;
  pvguDate: Date | null;
  uvguDate: Date | null;
  vigDate: Date | null;
  sendDate: Date | null;
  receiveDate: Date | null;
  commonTariff: number;
  rentTariff: number;
  itineraryId: string;
  containerNumber: number;
  loadStationCode: string;
  destStationCode: string;
  freightNetto: number;
  loadStationName: string;
  destStationName: string;
  sendNumber: string;
  etsngCode: string;
  freightName: string;
  trainIndex: string;
  senderOkpo: string;
  senderName: string;
  receiverOkpo: string;
  receiverName: string;
}

export interface WagonOperationModel {
  wagonNumber: number;
  stationCode: string;
  stationName: string;
  operationName: string;
  operationDate: Date;
}

export interface ConteinerOperationModel {
  conteinerNumber: number;
  stationCode: string;
  stationName: string;
  operationName: string;
  operationDate: Date;
}

export interface CompanyRepairParams {
  /// *Необязательнео поле при обновлении данных
  companyId: string;
  repairId: string;

  /// Номер акта выполнения работ
  actNumber: string;

  /// Дата акта выполнения работ
  actDate: any;

  /// Номер счет-фактуры
  invoiceNumber: string;

  /// Дата счет-фактуры
  invoiceDate: any;

  /// Стоимость ремонта
  repairCost: number;

  /// Примечание
  note: string;
}

export interface WagonFullInfo {
  number: number;

  // РЕЙС
  // Погрузка
  /**Название станции погрузки */
  loadStationName: string;

  /**Код станции погрузки */
  loadStationCode: string | null;

  /**Название дороги погрузки */
  loadRoadName: string;

  /**Дата погрузки */
  loadDate: Date | string | null;

  // Дислокация
  /**Название станции дислокации */
  dislStationName: string;

  /**Код станции дислокации */
  dislStationCode: string | null;

  /**Название дороги дислокации */
  dislRoadName: string;

  /**Название операции */
  operationName: string;

  /**Дата текущей дислокации */
  operationDate: Date | string | null;

  // Назначение
  /**Название станции назначения */
  destStationName: string;

  /**Код станции назначения */
  destStationCode: string | null;

  /**Название дороги назначения */
  destRoadName: string;

  /**Дата прибытия */
  receiveDate: Date | string | null;

  // Общая информаци рейса
  /**Название груза */
  etsngName: string;

  /**Вес груза */
  etsngNetto: number | null;

  /**Номер накладной */
  sendNumber: string;

  /**Индекс поезда */
  trainIndex: string;

  /**Номер поезда */
  trainNumber: string;

  /**Номер пути */
  wayNumber: string;

  /**Номер парка */
  parkNumber: string;

  /**Оставшееся расстояние */
  distance: number | null;

  // ПАСПОРТ
  /**Грузоподъемность (центнеры) */
  lifting: number | null;

  /**Код страны собственника */
  ownerCountryCode: number | null;

  /**Название страны собственника */
  ownerCountryName: string;

  /**Дата регистрации */
  registrationDate: Date | string | null;

  /**Дата постройки вагона */
  buildDate: Date | string | null;

  /**Код завода постройки */
  buildFactoryCode: number | null;

  /**Название завода постройки */
  buildFactoryName: string;

  /**Дата окончания срока службы */
  endDate: Date | string | null;

  /**Род вагона */
  wagonKind: number | null;

  /**Тип вагона */
  wagonType: number | null;

  /**Длина вагона по осям автосцепки (мм) */
  wagonLength: number | null;

  /**Вес тары вагона в центнерах */
  taraWeight: number | null;

  /**Код модели вагона */
  wagonModel: string;

  /**Название модели вагона */
  wagonModelName: string;

  /**Габарит */
  dimensions: number | null;

  /**Калибровка котла (для цистерн) */
  boilerCalibration: number | null;

  /**Код станции приписки */
  registrationStationCode: string | null;

  /**Название станции приписки */
  registrationStationName: string;

  /**Код дороги приписки вагона */
  registrationRoadCode: number | null;

  /**Название дороги приписки вагона */
  registrationRoadName: string;

  /**Код очередного планового ремонта вагона */
  scheduledPlanRepairCode: number | null;

  /**Название очередного планового ремонта вагона */
  scheduledPlanRepairName: string;

  /**Дата очередного планового ремонта вагона */
  scheduledPlanRepairDate: Date | string | null;

  /**Межрем. норматив пробега */
  runStandard: number | null;

  /**Разрешение перевода вагона на пробег собственного вагона */
  runPermission: boolean | null;

  /**Код дороги исключения */
  banRoadCode: number | null;

  /**Название дороги исключения */
  banRoadName: string;

  /**Код предприятия исключения */
  banDepotCode: number | null;

  /**Название предприятия исключения */
  banDepotName: string;

  /**Дата исключения вагона */
  banDate: Date | string | null;

  /**Признак того, что вагону запр. курс. по путям общ.польз */
  banSign: boolean | null;

  /**Признак продления срока службы */
  signLifeExtension: boolean | null;

  /**Межрем. норматив пробега */
  repairMileageStandard: number | null;

  /**Оборудование АСТ */
  astEquipment: boolean | null;

  /**Признак наличия датчиков */
  signSensors: boolean | null;

  /**Принадлежность к оператору */
  signOperator: boolean | null;

  /**Заводской номер */
  factoryNumber: string;

  /**Дата отпуска номера */
  issueDate: Date | string | null;

  /**Старый номер вагона */
  oldWagonNumber: number | null;

  /**Авторежим */
  autoMode: boolean | null;

  /**
   * Тип тормоза
   * 1-на тормозной площадке
   * 2-стояночный
   * 3-не оборудован"
   */
  brakeType: number | null;

  /**Название тормаза */
  brakeName: string;

  /**
   * Тип автосцепки
   * 1-СА-3
   * 2-СА-3М
   */
  couplerType: string;

  /**Название автосцепки */
  couplerName: string;

  /**Возможность постановки буфера */
  bufferOption: boolean | null;

  /**Код модели тележек */
  cartsModelCode: number | null;

  /**Подпольные балочки */
  undergroundBeams: boolean | null;

  /**Наличие сливного прибора */
  drainDevice: boolean | null;

  /**Наличие рамы */
  frame: boolean | null;

  /**Конструкция котла */
  boilerDesign: boolean | null;

  /**Локальный код собственника */
  localOwnerCode: number | null;

  /**ОКПО арендатора */
  rentOkpo: string;

  /**Название арендатора */
  rentName: string;

  /**Срок окончания аренды */
  rentEndDate: Date | string | null;

  /**Код станции приписки арендатора */
  rentStationCode: string | null;

  /**Название станции приписки арендатора */
  rentStationName: string;

  /**Код ВЧД последнего деп. ремонта */
  repairDepotCode: number | null;

  /**Название ВЧД последнего деп.ремонта */
  repairDepotName: string;

  /**Дата последнего деп. ремонт */
  repairDepotDate: Date | string | null;

  /**Код ВЧД последнего кап.ремонта */
  repairMajorCode: number | null;

  /**Название ВЧД последнего кап.ремонта */
  repairMajorName: string;

  /**Дата последнего кап. ремонт */
  repairMajorDate: Date | string | null;

  /**Ремонт по пробегу */
  repairInRun: boolean | null;

  // РЕМОНТЫ
  repairs: WagonFullInfoRepair[];

  // ДЕТАЛИ
  units: WagonFullInfoUnit[];

  // ДОКУМЕНТЫ
  docs: WagonFullInfoDoc[];

  // ПОСЛЕДНИЕ ОПЕРАЦИИ
  operations: WagonFullInfoOperation[];
}

export interface WagonFullInfoRepair {
  /**Название ремонта */
  repairName: string;

  /**Номер ВУ-23 */
  vu23Number: number | null;

  /**Дата ВУ-23 */
  vu23Date: Date | string | null;

  /**Номер ВУ-36 */
  vu36Number: number | null;

  /**Дата ВУ-36 */
  vu36Date: Date | string | null;

  /**Код станции ремонта */
  repairStationCode: string | null;

  /**Название станции ремонта */
  repairStationName: string;

  /**Код депо ремонта */
  repairDepotCode: number | null;

  /**Название депо ремонта */
  repairDepotName: string;

  /**Код станции перечисления в неисправные */
  failStationCode: string | null;

  /**Название станции перечисления в неисправные */
  failStationName: string;

  /**Код неисправности 1 */
  failCode1: number | null;

  /**Название неисправности 1 */
  failCodeName1: string;

  /**Код неисправности 2 */
  failCode2: number | null;

  /**Название неисправности 2 */
  failCodeName2: string;

  /**Код неисправности 3 */
  failCode3: number | null;

  /**Название неисправности 3 */
  failCodeName3: string;

  /**Фактическая дата ремонта */
  factRepairDate: Date | string | null;
}

export interface WagonFullInfoUnit {
  /**Название детали */
  detailName: string;

  /**Номер детали */
  detailNumber: number | null;

  /**Номер тележки */
  truckNumber: number | null;

  /**Установлена */
  isActive: boolean | null;

  /**Год изготовления */
  manufactureYear: number | null;

  /**Завод изготовитель */
  manufactureName: string;

  /**Страна */
  countryName: string;

  /**Дата сообщения */
  messageDate: Date | string | null;

  /**Дата ГВЦ */
  gvcDate: Date | string | null;
}

export interface WagonFullInfoDoc {
  /**Идентификатор договора */
  id: string | null;

  /**Название договора */
  name: string;

  /**Тип договора */
  type: string;

  /**Статус */
  status: string;

  /**Название организации */
  organizationName: string;

  /**Действует от */
  fromDate: Date | string | null;

  /**Действует до */
  toDate: Date | string | null;

  /**Дата создания */
  createDate: Date | string | null;
}

export interface WagonFullInfoOperation {
  /**Код станции погрузки */
  loadStationCode: string | null;

  /**Название станции погрузки */
  loadStationName: string;

  /**Дата погрузки */
  loadDateTime: Date | string | null;

  /**Код станции дислокации */
  dislStationCode: string | null;

  /**Название станции дислокации */
  dislStationName: string;

  /**Дата операции */
  operationDate: Date | string | null;

  /**Код станции назначения */
  destStationCode: string | null;

  /**Название станции назначения */
  destStationName: string;

  /**Дата прибытия */
  receiveDateTime: Date | string | null;

  /**Название операции */
  operationName: string;

  /**Номер накладной */
  sendNumber: string;

  /**Название груза */
  etsngName: string;

  /**Вес груза */
  etsngNetto: number | null;

  /**ОКПО отправителя */
  senderOkpo: string;

  /**Название отправителя */
  senderName: string;

  /**ОКПО получателя */
  receiverOkpo: string;

  /**Название получателя */
  receiverName: string;
}

export type CustomReportDetailsModel = {
  createDate: Date;
  /// <summary>
  /// Id
  /// </summary>
  id: number;

  /// <summary>
  /// Название отчёта
  /// </summary>
  name: string;

  groupId: number | null;

  groupName: string | null;

  /// <summary>
  /// Колонки отчёта
  /// </summary>
  fields: InfoBlockItemFields[];
  filters: FilterContentModel[];
  isDefault: boolean;
  catalogs: string[];
};

export interface CopyReportRequest {
  reportId: number;
  userIds: string[];
}
//calculateFilterExpression
export function ignoreCaseFilter(dxValue, selectedFilterOperations, target) {
  //@ts-ignore
  let result = [this.dataField, selectedFilterOperations, dxValue];
  //@ts-ignore
  if (this.dataType == 'string') {
    result.push('ignorecase');
  }
  return result;
}

export function checkIgnoreCase(filter, excluded?: string[]) {
  if (Array.isArray(filter)) {
    if (Array.isArray(filter[0])) {
      filter.forEach((f) => checkIgnoreCase(f, excluded));
    }
    if (filter[1] === 'contains' && (!excluded || !excluded.includes(filter[0])) && filter.length < 4) {
      filter.push('ignorecase');
    }
  }
}

export function hatchingRow(e) {
  const colors = [];
  if (e.data?.wagonnotes1) {
    JSON.parse(e.data.wagonnotes1).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }
  if (e.data?.wagonnotes2) {
    JSON.parse(e.data.wagonnotes2).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }
  if (e.data?.wagonnotes3) {
    JSON.parse(e.data.wagonnotes3).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }
  if (e.data?.wagonnotes4) {
    JSON.parse(e.data.wagonnotes4).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }

  if (e.data?.containernotes1) {
    JSON.parse(e.data.containernotes1).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }
  if (e.data?.containernotes2) {
    JSON.parse(e.data.containernotes2).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }
  if (e.data?.containernotes3) {
    JSON.parse(e.data.containernotes3).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }
  if (e.data?.containernotes4) {
    JSON.parse(e.data.containernotes4).forEach((n) => {
      if (n.description && n?.reportRowColor) {
        colors.push(n.description);
      }
    });
  }

  if (colors.length) {
    let gradient = `repeating-linear-gradient(180deg`;
    const inc = 36 / colors.length;
    let step = 0;
    colors.forEach((el, i) => {
      let hex = antColors[el].primary;
      gradient += `,${hex}20 ${step}px,${hex}20 ${step + inc}px`;
      step = step + inc;
    });
    gradient = gradient + ')';

    e.rowElement.style.background = gradient;
  }
}

export interface AttributeDefinition {
  name: string;
  dbName: string;
  description: string;
  dataType: string;
}
